import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { createGlobalStyle } from "styled-components";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

// Constants
import { APP_ASSETS_BUCKET_URL } from "constants/common";

//Assets
const IconSrc = `${APP_ASSETS_BUCKET_URL}/icon-datepicker.svg`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.label`
  font-family: Avenir;
  font-size: 14px;
  color: ${props => props.theme.colors.blue.colorBlue10};
  padding: 0;
  margin: 0;
`;

const InputContainer = styled.div`
  position: relative;
`;

const StyledInput = styled(ReactDatePicker)`
  width: 100%;
  padding: 9px 13px;
  background: ${props => props.theme.colors.white.colorWhite};
  border: 1px solid ${props => props.theme.colors.grey.colorGrey40};
  border-radius: 6px;
  box-sizing: border-box;
`;

const DatepickerStyles = createGlobalStyle`
  .react-datepicker__header {
    background-color: ${props => props.theme.colors.purple.colorPurple10};
  }

  .react-datepicker__day {
    font-size: 12px;
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: ${props => props.theme.colors.white.colorWhite};
    font-size: 12px;
  }
  
  .react-datepicker__navigation {
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    top: 18px;
  } 

  .react-datepicker__navigation--previous {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .react-datepicker__navigation--next {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    background-color: ${props => props.theme.colors.purple.colorPurple10} !important;
  }
  
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    margin: 5px;
  }
`;

const Icon = styled.img.attrs({ src: IconSrc, alt: "Datepicker alt" })`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Datepicker = ({ label, onChange, defaultValue, maxDate }) => {
  const [internalValue, setInternalValue] = useState(defaultValue || moment());

  useEffect(() => {
    onChange(internalValue);
  }, [internalValue]);

  return (
    <Container>
      <Label>{label}</Label>
      <InputContainer>
        <StyledInput
          selected={internalValue.toDate()}
          onChange={date => {
            const changedDate = moment(date);
            setInternalValue(actualValue => (changedDate.isValid() ? changedDate : actualValue));
          }}
          maxDate={maxDate.toDate()}
        />
        <Icon />
      </InputContainer>
      <DatepickerStyles />
    </Container>
  );
};

Datepicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment)
};

Datepicker.defaultProps = {
  defaultValue: null,
  maxDate: null
};
