const searchEngines = [
  "google",
  "bing",
  "yahoo",
  "baidu",
  "duckduckgo",
  "yandex",
  "ecosia",
  "aol",
  "ask",
  "openai",
  "chatgpt",
  "perplexity",
  "naver"
];

export const isOrganicAccess = () => {
  const referrer = localStorage.getItem("referrer");
  if (!referrer) return false;

  const referrerUrl = new URL(referrer);
  const hostname = referrerUrl.hostname.toLowerCase();
  const utmParams = ["utm_source", "utm_medium", "utm_campaign"];

  // Check if the referrer is a known search engine
  const isFromSearchEngine = searchEngines.some(engine => hostname.includes(engine));

  // Check for UTM parameters in the URL
  const hasUtmParams = utmParams.some(param => referrerUrl.searchParams.has(param));

  // Check if utm_source or utm_medium has "organic" as value
  const isUtmOrganic = ["utm_source", "utm_medium"].some(
    param => referrerUrl.searchParams.get(param) === "organic"
  );

  // Consider the access organic if it comes from a search engine, has no UTM params, or UTM params with the value "organic"
  return isFromSearchEngine && (!hasUtmParams || isUtmOrganic);
};
