import { lazy } from "react";
import paths from "../paths";
import { withPageWrapper } from "components/PageWrapper";

const DataProcessingAddendum = lazy(() => import("components/DataProcessingAddendum"));
const Privacy = lazy(() => import("components/Privacy"));
const Terms = lazy(() => import("components/Terms"));

export const legalRoutes = [
  { path: paths.legal.dataProcessingAddendum, Component: withPageWrapper(DataProcessingAddendum), app: true },
  { path: paths.legal.privacy, Component: withPageWrapper(Privacy), app: true },
  { path: paths.legal.terms, Component: withPageWrapper(Terms), app: true }
];
