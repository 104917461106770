// Tracking Events
import { trackEvent } from "@intercom/messenger-js-sdk";
import ReactPixel from "react-facebook-pixel";
import { trackAdsByRedux } from "../utm";
import { gaEvent } from "../trackEvents";

export const postUpgradeTrackingEvents = ({ plan, subscriptionId, listing }) => {
  // Google Analytics
  gaEvent({
    category: "Upgrade",
    action: "Start Trial",
    label: getGALabelStartTrial(listing)
  });

  gaEvent({
    category: "Upgrade",
    action: `Upgrade to ${plan.name} - ${plan.annual ? "Annual" : "Monthly"}`,
    label: getGALabelUpgrade(listing)
  });

  // trackAdsByRedux({
  //   from: "google",
  //   category: "Upgrade",
  //   action: "StartTrialUTM"
  // });

  // trackAdsByRedux({
  //   from: "google",
  //   category: "Upgrade",
  //   action: `Upgrade to ${plan.name} - ${plan.annual ? "Annual" : "Monthly"} UTM`
  // });

  // Facebook Pixel - conversion (StartTrial)
  ReactPixel.trackCustom(`StartTrial${plan.name}`);
  trackAdsByRedux({
    from: "facebook",
    action: `StartTrial${plan.name}UTM`
  });

  trackAdsByRedux({
    from: "facebook",
    action: "StartTrialUTM"
  });

  // Intercom
  trackEvent(`Plan Updated to ${plan.name}`);
};

const getGALabelStartTrial = listing => {
  // Users who created the account through our landing page
  const isLandingPageDirectSignUp = localStorage.getItem("landing_page_signup");

  if (isLandingPageDirectSignUp === "variant") {
    return "Email Signup Variant Start Trial";
  } else if (isLandingPageDirectSignUp === "control") {
    return "Email Signup Control Start Trial";
  } else if (isLandingPageDirectSignUp === "notIncluded") {
    return "Email Signup Not Included Start Trial";
  } else {
    if (listing === undefined) {
      return "";
    } else if (listing.premium === true) {
      return "Premium Start Trial";
    } else if (listing.premium === false) {
      return "Non-premium Start Trial";
    }
  }
};

const getGALabelUpgrade = listing => {
  // Users who created the account through our landing page
  const isLandingPageDirectSignUp = localStorage.getItem("landing_page_signup");

  if (isLandingPageDirectSignUp === "variant") {
    return "Email Signup Variant Upgrade";
  } else if (isLandingPageDirectSignUp === "control") {
    return "Email Signup Control Upgrade";
  } else if (isLandingPageDirectSignUp === "notIncluded") {
    return "Email Signup Not Included Upgrade";
  } else {
    if (listing === undefined) {
      return "";
    } else if (listing.premium === true) {
      return "Premium Upgrade";
    } else if (listing.premium === false) {
      return "Non-premium Upgrade";
    }
  }
};
