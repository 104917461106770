import React from "react";
import PropTypes from "prop-types";

const verticalDirection = {
  display: "flex",
  flexDirection: "column"
};

export const RadioGroup = ({ Component, name, selectedValue, disabled, onChange, children, ...props }) => {
  return (
    <Component role="radiogroup" name={name} {...props} style={verticalDirection}>
      {React.Children.map(children, element =>
        React.cloneElement(element, {
          ...element.props,
          checked: selectedValue === element.props.value,
          onChange: () => onChange(element.props.value),
          disabled: disabled,
          name
        })
      )}
    </Component>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
};

RadioGroup.defaultProps = {
  name: "",
  selectedValue: "",
  Component: "div"
};
