// Libs
import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { NewQueryClient } from "utils/queryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import OneSignal from "react-onesignal";

// Redux
import { Provider } from "react-redux";
import { store } from "utils/store/configureStore";

// Context
import { UserProvider } from "contexts/UserContext";

// Analytics Libs
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { GoogleTagManager } from "components/GoogleTagManager";
import { AnalyticsProvider } from "use-analytics";
import { Userpilot } from "userpilot";

// Components
import Routes from "./routes";
import "utils/i18n";
// Styles
import { ThemeProvider } from "styled-components";
import theme from "components/spocketUI/theme";

// Utils
import { analytics } from "utils/analytics";
import ErrorBoundary from "utils/errorBoundary";
import registerServiceWorker from "utils/registerServiceWorker";

const RELEASE = process.env.REACT_APP_VERSION;
if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging") {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY, {
    gaOptions: {
      allowLinker: true,
      cookieDomain: "auto"
    }
  });
  ReactGA.ga("require", "linker");
  ReactGA.ga("linker:autoLink", ["shopify.com", "shopify.ca"]);
  ReactGA.gtag("consent", "default", {
    ad_storage: "granted",
    ad_user_data: "granted",
    ad_personalization: "granted",
    analytics_storage: "granted"
  });

  /* Configuring sentry error reporting START */
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: RELEASE,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
    ignoreErrors: [
      /^REPLY_TIMEOUT/,
      "TypeError: Failed to fetch",
      "TypeError: i.kg.info is not a function",
      "TypeError: i.kg.info is not a function. (In 'i.kg.info(e)', 'i.kg.info' is undefined)",
      "Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target"
    ] // https://github.com/getsentry/sentry-javascript/issues/4605
  });
  /* Configuring sentry error reporting END */
}

// MSW - Mock Service Worker
if (process.env.NODE_ENV === "staging") {
  const { worker } = require("./mocks/browser");
  worker.start();
}

// React Facebook Pixel
ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL, {}, { debug: false, autoConfig: false });

// Userpilot
Userpilot.initialize(process.env.REACT_APP_USERPILOT_KEY);

const shouldLoadOneSignal =
  process.env.RECT_APP_LOAD_ONESIGNAL === true || process.env.RECT_APP_LOAD_ONESIGNAL === "true";

if (shouldLoadOneSignal) {
  OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
    notifyButton: { enable: true },
    allowLocalhostAsSecureOrigin: process.env.NODE_ENV === "development"
  });
}

// Avoiding frame busting in app
if (window.top !== window.self) {
  window.top.location = window.self.location;
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ErrorBoundary>
    <AnalyticsProvider instance={analytics}>
      <QueryClientProvider client={NewQueryClient}>
        <Provider store={store}>
          <UserProvider>
            <ThemeProvider theme={theme}>
              {process.env.NODE_ENV === "production" && (
                <GoogleTagManager gtmId={process.env.REACT_APP_GTM_ID} />
              )}

              <Routes />
            </ThemeProvider>
          </UserProvider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </AnalyticsProvider>
  </ErrorBoundary>
);

registerServiceWorker();
