import React, { useContext } from "react";

// Components
import { DropdownContext } from "components/spocketUI/components/Dropdown";

// Styles
import { Container } from "./DropdownItem.style";

const DropdownItem = ({ children, onClick, checked }) => {
  const { closeDropdown } = useContext(DropdownContext);
  return (
    <Container
      checked={checked}
      onClick={() => {
        onClick();
        closeDropdown && closeDropdown();
      }}
    >
      {children}
    </Container>
  );
};

export default DropdownItem;
