import React, { useRef } from "react";
import { default as ReactSelect, createFilter } from "react-select";
import { SelectStyle } from "./select/SelectStyle";
import IconOption from "./select/IconOption";
import Menu from "./select/Menu";
import SingleValue from "./select/SingleValue";
import ClearIndicator from "components/spocketUI/components/select/ClearIndicator";

export const Select = ({ menuTitle, onChange, horizontalMenu, showMenuHeader, customStyle, ...props }) => {
  const selectRef = useRef(null);
  const handleCloseMenu = () => () => {
    selectRef.current.select.blur();
  };
  return (
    <ReactSelect
      // https://github.com/JedWatson/react-select/issues/3128#issuecomment-487256349
      // Using filterOption massively reduces the lag when you're typing into the select.
      filterOption={createFilter({ ignoreAccents: false })}
      ref={selectRef}
      components={{
        Option: IconOption,
        ClearIndicator,
        SingleValue,
        Menu: props => {
          const { ...forwardProps } = props;
          return (
            <Menu
              closeMenu={handleCloseMenu()}
              menuTitle={menuTitle}
              showMenuHeader={showMenuHeader}
              {...forwardProps}
            />
          );
        }
      }}
      styles={customStyle ? customStyle : { ...SelectStyle }}
      onChange={onChange}
      {...props}
    />
  );
};

Select.defaultProps = {
  showMenuHeader: true
};
