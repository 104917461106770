// Constants
import { APP_ASSETS_BUCKET_URL } from "constants/common";

const AD = `${APP_ASSETS_BUCKET_URL}/countryFlags/AD.svg`;
const AE = `${APP_ASSETS_BUCKET_URL}/countryFlags/AE.svg`;
const AF = `${APP_ASSETS_BUCKET_URL}/countryFlags/AF.svg`;
const AG = `${APP_ASSETS_BUCKET_URL}/countryFlags/AG.svg`;
const AI = `${APP_ASSETS_BUCKET_URL}/countryFlags/AI.svg`;
const AL = `${APP_ASSETS_BUCKET_URL}/countryFlags/AL.svg`;
const AM = `${APP_ASSETS_BUCKET_URL}/countryFlags/AM.svg`;
const AN = `${APP_ASSETS_BUCKET_URL}/countryFlags/AN.svg`;
const AO = `${APP_ASSETS_BUCKET_URL}/countryFlags/AO.svg`;
const AR = `${APP_ASSETS_BUCKET_URL}/countryFlags/AR.svg`;
const AS = `${APP_ASSETS_BUCKET_URL}/countryFlags/AS.svg`;
const AT = `${APP_ASSETS_BUCKET_URL}/countryFlags/AT.svg`;
const AU = `${APP_ASSETS_BUCKET_URL}/countryFlags/AU.svg`;
const AW = `${APP_ASSETS_BUCKET_URL}/countryFlags/AW.svg`;
const AX = `${APP_ASSETS_BUCKET_URL}/countryFlags/AX.svg`;
const AZ = `${APP_ASSETS_BUCKET_URL}/countryFlags/AZ.svg`;
const BA = `${APP_ASSETS_BUCKET_URL}/countryFlags/BA.svg`;
const BB = `${APP_ASSETS_BUCKET_URL}/countryFlags/BB.svg`;
const BD = `${APP_ASSETS_BUCKET_URL}/countryFlags/BD.svg`;
const BE = `${APP_ASSETS_BUCKET_URL}/countryFlags/BE.svg`;
const BF = `${APP_ASSETS_BUCKET_URL}/countryFlags/BF.svg`;
const BG = `${APP_ASSETS_BUCKET_URL}/countryFlags/BG.svg`;
const BH = `${APP_ASSETS_BUCKET_URL}/countryFlags/BH.svg`;
const BI = `${APP_ASSETS_BUCKET_URL}/countryFlags/BI.svg`;
const BJ = `${APP_ASSETS_BUCKET_URL}/countryFlags/BJ.svg`;
const BL = `${APP_ASSETS_BUCKET_URL}/countryFlags/BL.svg`;
const BM = `${APP_ASSETS_BUCKET_URL}/countryFlags/BM.svg`;
const BN = `${APP_ASSETS_BUCKET_URL}/countryFlags/BN.svg`;
const BO = `${APP_ASSETS_BUCKET_URL}/countryFlags/BO.svg`;
const BR = `${APP_ASSETS_BUCKET_URL}/countryFlags/BR.svg`;
const BS = `${APP_ASSETS_BUCKET_URL}/countryFlags/BS.svg`;
const BT = `${APP_ASSETS_BUCKET_URL}/countryFlags/BT.svg`;
const BV = `${APP_ASSETS_BUCKET_URL}/countryFlags/BV.svg`;
const BW = `${APP_ASSETS_BUCKET_URL}/countryFlags/BW.svg`;
const BY = `${APP_ASSETS_BUCKET_URL}/countryFlags/BY.svg`;
const BZ = `${APP_ASSETS_BUCKET_URL}/countryFlags/BZ.svg`;
const CA = `${APP_ASSETS_BUCKET_URL}/countryFlags/CA.svg`;
const CAF = `${APP_ASSETS_BUCKET_URL}/countryFlags/CAF.svg`;
const CAS = `${APP_ASSETS_BUCKET_URL}/countryFlags/CAS.svg`;
const CC = `${APP_ASSETS_BUCKET_URL}/countryFlags/CC.svg`;
const CD = `${APP_ASSETS_BUCKET_URL}/countryFlags/CD.svg`;
const CEU = `${APP_ASSETS_BUCKET_URL}/countryFlags/CEU.svg`;
const CF = `${APP_ASSETS_BUCKET_URL}/countryFlags/CF.svg`;
const CG = `${APP_ASSETS_BUCKET_URL}/countryFlags/CG.svg`;
const CH = `${APP_ASSETS_BUCKET_URL}/countryFlags/CH.svg`;
const CI = `${APP_ASSETS_BUCKET_URL}/countryFlags/CI.svg`;
const CK = `${APP_ASSETS_BUCKET_URL}/countryFlags/CK.svg`;
const CL = `${APP_ASSETS_BUCKET_URL}/countryFlags/CL.svg`;
const CM = `${APP_ASSETS_BUCKET_URL}/countryFlags/CM.svg`;
const CN = `${APP_ASSETS_BUCKET_URL}/countryFlags/CN.svg`;
const CNA = `${APP_ASSETS_BUCKET_URL}/countryFlags/CNA.svg`;
const CO = `${APP_ASSETS_BUCKET_URL}/countryFlags/CO.svg`;
const COC = `${APP_ASSETS_BUCKET_URL}/countryFlags/COC.svg`;
const CR = `${APP_ASSETS_BUCKET_URL}/countryFlags/CR.svg`;
const CSA = `${APP_ASSETS_BUCKET_URL}/countryFlags/CSA.svg`;
const CU = `${APP_ASSETS_BUCKET_URL}/countryFlags/CU.svg`;
const CV = `${APP_ASSETS_BUCKET_URL}/countryFlags/CV.svg`;
const CW = `${APP_ASSETS_BUCKET_URL}/countryFlags/CW.svg`;
const CX = `${APP_ASSETS_BUCKET_URL}/countryFlags/CX.svg`;
const CY = `${APP_ASSETS_BUCKET_URL}/countryFlags/CY.svg`;
const CZ = `${APP_ASSETS_BUCKET_URL}/countryFlags/CZ.svg`;
const DE = `${APP_ASSETS_BUCKET_URL}/countryFlags/DE.svg`;
const DJ = `${APP_ASSETS_BUCKET_URL}/countryFlags/DJ.svg`;
const DK = `${APP_ASSETS_BUCKET_URL}/countryFlags/DK.svg`;
const DM = `${APP_ASSETS_BUCKET_URL}/countryFlags/DM.svg`;
const DO = `${APP_ASSETS_BUCKET_URL}/countryFlags/DO.svg`;
const DZ = `${APP_ASSETS_BUCKET_URL}/countryFlags/DZ.svg`;
const EC = `${APP_ASSETS_BUCKET_URL}/countryFlags/EC.svg`;
const EE = `${APP_ASSETS_BUCKET_URL}/countryFlags/EE.svg`;
const EG = `${APP_ASSETS_BUCKET_URL}/countryFlags/EG.svg`;
const EH = `${APP_ASSETS_BUCKET_URL}/countryFlags/EH.svg`;
const ER = `${APP_ASSETS_BUCKET_URL}/countryFlags/ER.svg`;
const ES = `${APP_ASSETS_BUCKET_URL}/countryFlags/ES.svg`;
const ET = `${APP_ASSETS_BUCKET_URL}/countryFlags/ET.svg`;
const EU = `${APP_ASSETS_BUCKET_URL}/countryFlags/EU.svg`;
const FI = `${APP_ASSETS_BUCKET_URL}/countryFlags/FI.svg`;
const FJ = `${APP_ASSETS_BUCKET_URL}/countryFlags/FJ.svg`;
const FK = `${APP_ASSETS_BUCKET_URL}/countryFlags/FK.svg`;
const FM = `${APP_ASSETS_BUCKET_URL}/countryFlags/FM.svg`;
const FO = `${APP_ASSETS_BUCKET_URL}/countryFlags/FO.svg`;
const FR = `${APP_ASSETS_BUCKET_URL}/countryFlags/FR.svg`;
const GA = `${APP_ASSETS_BUCKET_URL}/countryFlags/GA.svg`;
const GB = `${APP_ASSETS_BUCKET_URL}/countryFlags/GB.svg`;
const GD = `${APP_ASSETS_BUCKET_URL}/countryFlags/GD.svg`;
const GE = `${APP_ASSETS_BUCKET_URL}/countryFlags/GE.svg`;
const GF = `${APP_ASSETS_BUCKET_URL}/countryFlags/GF.svg`;
const GG = `${APP_ASSETS_BUCKET_URL}/countryFlags/GG.svg`;
const GH = `${APP_ASSETS_BUCKET_URL}/countryFlags/GH.svg`;
const GI = `${APP_ASSETS_BUCKET_URL}/countryFlags/GI.svg`;
const GL = `${APP_ASSETS_BUCKET_URL}/countryFlags/GL.svg`;
const GM = `${APP_ASSETS_BUCKET_URL}/countryFlags/GM.svg`;
const GN = `${APP_ASSETS_BUCKET_URL}/countryFlags/GN.svg`;
const GP = `${APP_ASSETS_BUCKET_URL}/countryFlags/GP.svg`;
const GQ = `${APP_ASSETS_BUCKET_URL}/countryFlags/GQ.svg`;
const GR = `${APP_ASSETS_BUCKET_URL}/countryFlags/GR.svg`;
const GS = `${APP_ASSETS_BUCKET_URL}/countryFlags/GS.svg`;
const GT = `${APP_ASSETS_BUCKET_URL}/countryFlags/GT.svg`;
const GU = `${APP_ASSETS_BUCKET_URL}/countryFlags/GU.svg`;
const GW = `${APP_ASSETS_BUCKET_URL}/countryFlags/GW.svg`;
const GY = `${APP_ASSETS_BUCKET_URL}/countryFlags/GY.svg`;
const HK = `${APP_ASSETS_BUCKET_URL}/countryFlags/HK.svg`;
const HM = `${APP_ASSETS_BUCKET_URL}/countryFlags/HM.svg`;
const HN = `${APP_ASSETS_BUCKET_URL}/countryFlags/HN.svg`;
const HR = `${APP_ASSETS_BUCKET_URL}/countryFlags/HR.svg`;
const HT = `${APP_ASSETS_BUCKET_URL}/countryFlags/HT.svg`;
const HU = `${APP_ASSETS_BUCKET_URL}/countryFlags/HU.svg`;
const IC = `${APP_ASSETS_BUCKET_URL}/countryFlags/IC.svg`;
const ID = `${APP_ASSETS_BUCKET_URL}/countryFlags/ID.svg`;
const IE = `${APP_ASSETS_BUCKET_URL}/countryFlags/IE.svg`;
const IL = `${APP_ASSETS_BUCKET_URL}/countryFlags/IL.svg`;
const IM = `${APP_ASSETS_BUCKET_URL}/countryFlags/IM.svg`;
const IN = `${APP_ASSETS_BUCKET_URL}/countryFlags/IN.svg`;
const IO = `${APP_ASSETS_BUCKET_URL}/countryFlags/IO.svg`;
const IQ = `${APP_ASSETS_BUCKET_URL}/countryFlags/IQ.svg`;
const IR = `${APP_ASSETS_BUCKET_URL}/countryFlags/IR.svg`;
const IS = `${APP_ASSETS_BUCKET_URL}/countryFlags/IS.svg`;
const IT = `${APP_ASSETS_BUCKET_URL}/countryFlags/IT.svg`;
const JE = `${APP_ASSETS_BUCKET_URL}/countryFlags/JE.svg`;
const JM = `${APP_ASSETS_BUCKET_URL}/countryFlags/JM.svg`;
const JO = `${APP_ASSETS_BUCKET_URL}/countryFlags/JO.svg`;
const JP = `${APP_ASSETS_BUCKET_URL}/countryFlags/JP.svg`;
const KE = `${APP_ASSETS_BUCKET_URL}/countryFlags/KE.svg`;
const KG = `${APP_ASSETS_BUCKET_URL}/countryFlags/KG.svg`;
const KH = `${APP_ASSETS_BUCKET_URL}/countryFlags/KH.svg`;
const KI = `${APP_ASSETS_BUCKET_URL}/countryFlags/KI.svg`;
const KM = `${APP_ASSETS_BUCKET_URL}/countryFlags/KM.svg`;
const KN = `${APP_ASSETS_BUCKET_URL}/countryFlags/KN.svg`;
const KP = `${APP_ASSETS_BUCKET_URL}/countryFlags/KP.svg`;
const KR = `${APP_ASSETS_BUCKET_URL}/countryFlags/KR.svg`;
const KV = `${APP_ASSETS_BUCKET_URL}/countryFlags/KV.svg`;
const KW = `${APP_ASSETS_BUCKET_URL}/countryFlags/KW.svg`;
const KY = `${APP_ASSETS_BUCKET_URL}/countryFlags/KY.svg`;
const KZ = `${APP_ASSETS_BUCKET_URL}/countryFlags/KZ.svg`;
const LA = `${APP_ASSETS_BUCKET_URL}/countryFlags/LA.svg`;
const LB = `${APP_ASSETS_BUCKET_URL}/countryFlags/LB.svg`;
const LC = `${APP_ASSETS_BUCKET_URL}/countryFlags/LC.svg`;
const LI = `${APP_ASSETS_BUCKET_URL}/countryFlags/LI.svg`;
const LK = `${APP_ASSETS_BUCKET_URL}/countryFlags/LK.svg`;
const LR = `${APP_ASSETS_BUCKET_URL}/countryFlags/LR.svg`;
const LS = `${APP_ASSETS_BUCKET_URL}/countryFlags/LS.svg`;
const LT = `${APP_ASSETS_BUCKET_URL}/countryFlags/LT.svg`;
const LU = `${APP_ASSETS_BUCKET_URL}/countryFlags/LU.svg`;
const LV = `${APP_ASSETS_BUCKET_URL}/countryFlags/LV.svg`;
const LY = `${APP_ASSETS_BUCKET_URL}/countryFlags/LY.svg`;
const MA = `${APP_ASSETS_BUCKET_URL}/countryFlags/MA.svg`;
const MC = `${APP_ASSETS_BUCKET_URL}/countryFlags/MC.svg`;
const MD = `${APP_ASSETS_BUCKET_URL}/countryFlags/MD.svg`;
const ME = `${APP_ASSETS_BUCKET_URL}/countryFlags/ME.svg`;
const MF = `${APP_ASSETS_BUCKET_URL}/countryFlags/MF.svg`;
const MG = `${APP_ASSETS_BUCKET_URL}/countryFlags/MG.svg`;
const MH = `${APP_ASSETS_BUCKET_URL}/countryFlags/MH.svg`;
const MK = `${APP_ASSETS_BUCKET_URL}/countryFlags/MK.svg`;
const ML = `${APP_ASSETS_BUCKET_URL}/countryFlags/ML.svg`;
const MM = `${APP_ASSETS_BUCKET_URL}/countryFlags/MM.svg`;
const MN = `${APP_ASSETS_BUCKET_URL}/countryFlags/MN.svg`;
const MO = `${APP_ASSETS_BUCKET_URL}/countryFlags/MO.svg`;
const MP = `${APP_ASSETS_BUCKET_URL}/countryFlags/MP.svg`;
const MQ = `${APP_ASSETS_BUCKET_URL}/countryFlags/MQ.svg`;
const MR = `${APP_ASSETS_BUCKET_URL}/countryFlags/MR.svg`;
const MS = `${APP_ASSETS_BUCKET_URL}/countryFlags/MS.svg`;
const MT = `${APP_ASSETS_BUCKET_URL}/countryFlags/MT.svg`;
const MU = `${APP_ASSETS_BUCKET_URL}/countryFlags/MU.svg`;
const MV = `${APP_ASSETS_BUCKET_URL}/countryFlags/MV.svg`;
const MW = `${APP_ASSETS_BUCKET_URL}/countryFlags/MW.svg`;
const MX = `${APP_ASSETS_BUCKET_URL}/countryFlags/MX.svg`;
const MY = `${APP_ASSETS_BUCKET_URL}/countryFlags/MY.svg`;
const MZ = `${APP_ASSETS_BUCKET_URL}/countryFlags/MZ.svg`;
const NA = `${APP_ASSETS_BUCKET_URL}/countryFlags/NA.svg`;
const NC = `${APP_ASSETS_BUCKET_URL}/countryFlags/NC.svg`;
const NE = `${APP_ASSETS_BUCKET_URL}/countryFlags/NE.svg`;
const NF = `${APP_ASSETS_BUCKET_URL}/countryFlags/NF.svg`;
const NG = `${APP_ASSETS_BUCKET_URL}/countryFlags/NG.svg`;
const NI = `${APP_ASSETS_BUCKET_URL}/countryFlags/NI.svg`;
const NL = `${APP_ASSETS_BUCKET_URL}/countryFlags/NL.svg`;
const NO = `${APP_ASSETS_BUCKET_URL}/countryFlags/NO.svg`;
const NP = `${APP_ASSETS_BUCKET_URL}/countryFlags/NP.svg`;
const NR = `${APP_ASSETS_BUCKET_URL}/countryFlags/NR.svg`;
const NU = `${APP_ASSETS_BUCKET_URL}/countryFlags/NU.svg`;
const NZ = `${APP_ASSETS_BUCKET_URL}/countryFlags/NZ.svg`;
const OM = `${APP_ASSETS_BUCKET_URL}/countryFlags/OM.svg`;
const PA = `${APP_ASSETS_BUCKET_URL}/countryFlags/PA.svg`;
const PE = `${APP_ASSETS_BUCKET_URL}/countryFlags/PE.svg`;
const PF = `${APP_ASSETS_BUCKET_URL}/countryFlags/PF.svg`;
const PG = `${APP_ASSETS_BUCKET_URL}/countryFlags/PG.svg`;
const PH = `${APP_ASSETS_BUCKET_URL}/countryFlags/PH.svg`;
const PK = `${APP_ASSETS_BUCKET_URL}/countryFlags/PK.svg`;
const PL = `${APP_ASSETS_BUCKET_URL}/countryFlags/PL.svg`;
const PM = `${APP_ASSETS_BUCKET_URL}/countryFlags/PM.svg`;
const PN = `${APP_ASSETS_BUCKET_URL}/countryFlags/PN.svg`;
const PR = `${APP_ASSETS_BUCKET_URL}/countryFlags/PR.svg`;
const PS = `${APP_ASSETS_BUCKET_URL}/countryFlags/PS.svg`;
const PT = `${APP_ASSETS_BUCKET_URL}/countryFlags/PT.svg`;
const PW = `${APP_ASSETS_BUCKET_URL}/countryFlags/PW.svg`;
const PY = `${APP_ASSETS_BUCKET_URL}/countryFlags/PY.svg`;
const QA = `${APP_ASSETS_BUCKET_URL}/countryFlags/QA.svg`;
const RE = `${APP_ASSETS_BUCKET_URL}/countryFlags/RE.svg`;
const RO = `${APP_ASSETS_BUCKET_URL}/countryFlags/RO.svg`;
const RS = `${APP_ASSETS_BUCKET_URL}/countryFlags/RS.svg`;
const RU = `${APP_ASSETS_BUCKET_URL}/countryFlags/RU.svg`;
const RW = `${APP_ASSETS_BUCKET_URL}/countryFlags/RW.svg`;
const SA = `${APP_ASSETS_BUCKET_URL}/countryFlags/SA.svg`;
const SB = `${APP_ASSETS_BUCKET_URL}/countryFlags/SB.svg`;
const SC = `${APP_ASSETS_BUCKET_URL}/countryFlags/SC.svg`;
const SD = `${APP_ASSETS_BUCKET_URL}/countryFlags/SD.svg`;
const SE = `${APP_ASSETS_BUCKET_URL}/countryFlags/SE.svg`;
const SG = `${APP_ASSETS_BUCKET_URL}/countryFlags/SG.svg`;
const SH = `${APP_ASSETS_BUCKET_URL}/countryFlags/SH.svg`;
const SI = `${APP_ASSETS_BUCKET_URL}/countryFlags/SI.svg`;
const SJ = `${APP_ASSETS_BUCKET_URL}/countryFlags/SJ.svg`;
const SK = `${APP_ASSETS_BUCKET_URL}/countryFlags/SK.svg`;
const SL = `${APP_ASSETS_BUCKET_URL}/countryFlags/SL.svg`;
const SM = `${APP_ASSETS_BUCKET_URL}/countryFlags/SM.svg`;
const SN = `${APP_ASSETS_BUCKET_URL}/countryFlags/SN.svg`;
const SO = `${APP_ASSETS_BUCKET_URL}/countryFlags/SO.svg`;
const SR = `${APP_ASSETS_BUCKET_URL}/countryFlags/SR.svg`;
const SS = `${APP_ASSETS_BUCKET_URL}/countryFlags/SS.svg`;
const ST = `${APP_ASSETS_BUCKET_URL}/countryFlags/ST.svg`;
const SV = `${APP_ASSETS_BUCKET_URL}/countryFlags/SV.svg`;
const SX = `${APP_ASSETS_BUCKET_URL}/countryFlags/SX.svg`;
const SY = `${APP_ASSETS_BUCKET_URL}/countryFlags/SY.svg`;
const SZ = `${APP_ASSETS_BUCKET_URL}/countryFlags/SZ.svg`;
const TC = `${APP_ASSETS_BUCKET_URL}/countryFlags/TC.svg`;
const TD = `${APP_ASSETS_BUCKET_URL}/countryFlags/TD.svg`;
const TF = `${APP_ASSETS_BUCKET_URL}/countryFlags/TF.svg`;
const TG = `${APP_ASSETS_BUCKET_URL}/countryFlags/TG.svg`;
const TH = `${APP_ASSETS_BUCKET_URL}/countryFlags/TH.svg`;
const TJ = `${APP_ASSETS_BUCKET_URL}/countryFlags/TJ.svg`;
const TK = `${APP_ASSETS_BUCKET_URL}/countryFlags/TK.svg`;
const TL = `${APP_ASSETS_BUCKET_URL}/countryFlags/TL.svg`;
const TM = `${APP_ASSETS_BUCKET_URL}/countryFlags/TM.svg`;
const TN = `${APP_ASSETS_BUCKET_URL}/countryFlags/TN.svg`;
const TO = `${APP_ASSETS_BUCKET_URL}/countryFlags/TO.svg`;
const TR = `${APP_ASSETS_BUCKET_URL}/countryFlags/TR.svg`;
const TT = `${APP_ASSETS_BUCKET_URL}/countryFlags/TT.svg`;
const TV = `${APP_ASSETS_BUCKET_URL}/countryFlags/TV.svg`;
const TW = `${APP_ASSETS_BUCKET_URL}/countryFlags/TW.svg`;
const TZ = `${APP_ASSETS_BUCKET_URL}/countryFlags/TZ.svg`;
const UA = `${APP_ASSETS_BUCKET_URL}/countryFlags/UA.svg`;
const UG = `${APP_ASSETS_BUCKET_URL}/countryFlags/UG.svg`;
const UM = `${APP_ASSETS_BUCKET_URL}/countryFlags/UM.svg`;
const US = `${APP_ASSETS_BUCKET_URL}/countryFlags/US.svg`;
const UY = `${APP_ASSETS_BUCKET_URL}/countryFlags/UY.svg`;
const UZ = `${APP_ASSETS_BUCKET_URL}/countryFlags/UZ.svg`;
const VA = `${APP_ASSETS_BUCKET_URL}/countryFlags/VA.svg`;
const VC = `${APP_ASSETS_BUCKET_URL}/countryFlags/VC.svg`;
const VE = `${APP_ASSETS_BUCKET_URL}/countryFlags/VE.svg`;
const VG = `${APP_ASSETS_BUCKET_URL}/countryFlags/VG.svg`;
const VI = `${APP_ASSETS_BUCKET_URL}/countryFlags/VI.svg`;
const VN = `${APP_ASSETS_BUCKET_URL}/countryFlags/VN.svg`;
const VU = `${APP_ASSETS_BUCKET_URL}/countryFlags/VU.svg`;
const WF = `${APP_ASSETS_BUCKET_URL}/countryFlags/WF.svg`;
const WS = `${APP_ASSETS_BUCKET_URL}/countryFlags/WS.svg`;
const WW = `${APP_ASSETS_BUCKET_URL}/countryFlags/WW.svg`;
const XK = `${APP_ASSETS_BUCKET_URL}/countryFlags/XK.svg`;
const YE = `${APP_ASSETS_BUCKET_URL}/countryFlags/YE.svg`;
const YT = `${APP_ASSETS_BUCKET_URL}/countryFlags/YT.svg`;
const ZA = `${APP_ASSETS_BUCKET_URL}/countryFlags/ZA.svg`;
const ZM = `${APP_ASSETS_BUCKET_URL}/countryFlags/ZM.svg`;
const ZW = `${APP_ASSETS_BUCKET_URL}/countryFlags/ZW.svg`;

export default {
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AN,
  AO,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CAF,
  CAS,
  CC,
  CD,
  CEU,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CNA,
  CO,
  COC,
  CR,
  CSA,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  EU,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  IC,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KV,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  WW,
  XK,
  YE,
  YT,
  ZA,
  ZM,
  ZW
};
