import { lazy } from "react";
import paths from "../paths";
import { withPageWrapper } from "components/PageWrapper";

const PlanRedirect = lazy(() => import("components/PlanRedirect"));
const Academy = lazy(() => import("pages/academy").then(module => ({ default: module.Academy })));
const SpocketDeals = lazy(() => import("pages/spocketDeals"));

export const promotionRoutes = [
  { path: paths.promotions.planRedirect, Component: withPageWrapper(PlanRedirect), app: true },
  { path: paths.promotions.academy, Component: withPageWrapper(Academy), app: true, shop: true },
  { path: paths.promotions.spocketDeals, Component: withPageWrapper(SpocketDeals), app: true, shop: true }
];
