import React from "react";

import { Container, Card, Message, StyledButton, Title } from "./ErrorPage.style";
import { useTranslation } from "react-i18next";

export const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Card>
        <Title>{t("ErrorPage.Title")}</Title>
        <Message>{t("ErrorPage.Description")}</Message>
        <StyledButton
          data-heap="error-page-reload-button"
          title={t("ErrorPage.Button.Text")}
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("ErrorPage.Button.Text")}
        </StyledButton>
      </Card>
    </Container>
  );
};

export default ErrorPage;
