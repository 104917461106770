import styled from "styled-components";

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 8px -8px;
`;

const MenuHeader = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #6f7582;
  text-transform: uppercase;
`;

const CloseIcon = styled.img`
  cursor: pointer;
  margin-right: 16px;
`;

export { MenuContainer, MenuHeader, CloseIcon };
