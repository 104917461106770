import React from "react";
import styled from "styled-components";

import countriesFlags from "./countryFlag/countriesFlags";

const NullFlag = styled.span`
  width: 15px;
  height: 12px;
  border-radius: 2px;
  margin: 0 4px;
  background-color: #eaeaea;
`;

export const CountryFlag = ({ isoCountryCode, label }) => {
  const alt = label ? `${label} flag` : "flag";
  const flag = isoCountryCode && countriesFlags[isoCountryCode.toUpperCase()];

  if (!flag) {
    return <NullFlag />;
  } else {
    return <img src={flag} alt={alt} className="country-flag" />;
  }
};
