import React, { lazy } from "react";
import paths from "../paths";
import { withPageWrapper } from "components/PageWrapper";

const Suppliers = lazy(() => import("pages/Suppliers"));

export const supplierRoutes = [
  { path: paths.suppliers, Component: withPageWrapper(() => <Suppliers hasSupplier={false} />), app: true },
  {
    path: `${paths.suppliers}/:supplierAlias`,
    Component: withPageWrapper(() => <Suppliers hasSupplier={true} />),
    app: true
  }
];
