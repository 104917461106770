import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  text-align: left;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
  width: 16px;
  height: 16px;
`;

const Label = styled.label`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  padding: 5px 10px 5px 0;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: #222939;
  align-items: center;
  border-radius: 4px;
  background-color: ${props => (props.checked ? "#f4f5f8" : "white")};

  :hover {
    .StyledCheckbox {
      border-color: #7020ff;
    }
  }
`;

const Title = styled.span`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

// Hide checkbox visually but remain accessible to screen readers.
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  :focus + span {
    .StyledCheckbox {
      box-shadow: 0 0 0 2px #e9e6ff;
    }
  }
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? "#7020ff" : "white")};
  border-radius: 2px;
  border: solid 1px ${props => (props.checked ? "#7020ff" : "#ccd2e1")};
  transition: all 300ms ease-out;

  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")};
  }
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: #6f7582;
  padding-left: 24px;
  margin-top: 8px;
`;

const Content = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export { CheckboxContainer, Icon, HiddenCheckbox, StyledCheckbox, Label, Description, Content, Title };
