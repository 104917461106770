export const SelectStyle = {
  container: (styles, state) => ({
    ...styles,
    width: "100%",
    transition: "all 300ms ease-out"
  }),
  control: (styles, { isFocused, selectProps }) => {
    const { style } = selectProps;

    return {
      ...styles,
      cursor: "text",
      borderRadius: "4px",
      height: "40px",
      backgroundColor: "#fafbfc",
      outline: "unset",
      border: `1px solid ${isFocused ? "#bab1ff" : "#e6e8f0"}`,
      boxShadow: isFocused ? "0 0 0px 2pt rgba(186,177,255,0.3)" : "unset",
      fontSize: "14px",
      lineHeight: "1.14",
      color: "#222939",
      fontWeight: "500",

      ":hover": {
        borderColor: isFocused ? "#bab1ff" : "#ccd2e1"
      },
      ...style
    };
  },
  indicatorSeparator: styles => ({
    ...styles,
    display: "none"
  }),

  dropdownIndicator: (styles, { isFocused }) => ({
    padding: "0px !important",
    marginRight: "10px",
    color: "#9da5b8",
    height: "100%",
    display: "flex",
    alignItems: "center",
    "> svg": {
      height: "12px"
    },
    cursor: "pointer"
  }),
  menu: (styles, { selectProps }) => {
    const { style } = selectProps;

    return {
      ...styles,
      borderRadius: "8px",
      boxShadow: "0 4px 20px 0 rgba(34, 41, 57, 0.15)",
      backgroundColor: "#ffffff",
      padding: "21px 4px 21px 16px",
      width: "228px",

      marginTop: "4px",
      ...style,
      "@media (max-width: 425px)": {
        width: "100%"
      }
    };
  },
  menuList: styles => ({
    ...styles,
    maxHeight: "304px",
    paddingRight: "8px",
    marginLeft: "-8px",
    "&::-webkit-scrollbar": {
      width: "4px"
    },

    "&::-webkit-scrollbar-track": {},

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      borderRadius: "2px"
    }
  }),
  placeholder: styles => ({
    ...styles,
    color: "#6f7582",
    fontWeight: "normal"
  }),
  input: styles => ({
    ...styles
  }),
  option: (styles, { isFocused, isSelected }) => {
    const selectedColor = "#f4f5f8";
    const hoverColor = "#fafbfc";

    return {
      ...styles,
      cursor: "pointer",
      color: "#222939",
      borderRadius: "4px",
      padding: "4px 8px",
      marginBottom: "4px",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "1.33",
      backgroundColor: isSelected ? selectedColor : isFocused && hoverColor,

      ":hover": {
        backgroundColor: isSelected ? selectedColor : hoverColor
      }
    };
  }
};

export const horizontalOrientation = {
  ...SelectStyle,
  menuList: styles => ({
    ...styles,
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "100%",
    "> div:nth-child(odd)": {
      marginRight: "32px",
      width: "calc(50% - 32px)"
    },
    "> div": {
      width: "50%"
    },

    "@media (max-width: 425px)": {
      "> div:nth-child(odd)": {
        marginRight: "unset",
        width: "100%"
      },
      "> div": {
        width: "100%"
      }
    }
  })
};
