// Libs
import React from "react";
import PropTypes from "prop-types";

// Style
import { Container, VerticalLine, Span } from "./VerticalDivider.style";

export const VerticalDivider = ({ children }) => {
  return (
    <Container>
      <VerticalLine />
      <Span>{children}</Span>
      <VerticalLine />
    </Container>
  );
};

VerticalDivider.propTypes = {
  children: PropTypes.any
};
