import ApiCall from "utils/apiCall";
import Cookies from "js-cookie";
import { isOrganicAccess } from "utils/isOrganicAccess";

const initialState = {
  isFetching: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "utm.isFetching":
      return { ...state, isFetching: true };

    case "utm.fetchSuccess":
      return { ...state, isFetching: false };

    case "utm.fetchError":
      return { ...state, isFetching: false };

    default:
      return state;
  }
}

// Actions
export function sendUtm() {
  return dispatch => {
    let spocketUtm = Cookies.get("spocket_utm");
    const isOrganic = isOrganicAccess();
    if (!spocketUtm && isOrganic) {
      spocketUtm = "?utm_source=organic";
    }
    if (spocketUtm) {
      // we want to add other cookies to utm payload
      // so far, we want to add the ps_xid cookie
      // but we can add more if needed
      let updatedUtm = spocketUtm;
      const otherCookies = [
        "ps_xid", // partner stack
        "sscid", // share a sale
        "irclickid" // impact radius
      ];
      otherCookies.forEach(name => {
        const value = Cookies.get(name);
        if (value) {
          updatedUtm += `&${name}=${value}`;
        }
      });

      dispatch({ type: "utm.isFetching" });
      ApiCall.post(`/stores/utm`, formatUtm(updatedUtm))
        .then(() => dispatch({ type: "utm.fetchSuccess" }))
        .catch(error => {
          dispatch({ type: "utm.fetchError", error });
        });
    }
  };
}

// Utils
const formatUtm = utms => {
  const arrayOfUtms = utms
    .split("?")[1]
    .split("&")
    .map(utm => {
      const splitedUtm = utm.split("=");
      const key = splitedUtm[0];
      const value = splitedUtm[1];
      return { [key]: value };
    });

  return Object.assign({}, ...arrayOfUtms);
};
