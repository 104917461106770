import React from "react";
import { components } from "react-select";

import { MenuContainer, MenuHeader, CloseIcon } from "./MenuStyle";

import iconClose from "./images/icon-close.svg";

const Menu = ({ menuTitle, showMenuHeader, ...props }) => {
  return (
    <components.Menu {...props}>
      {showMenuHeader ? (
        <MenuContainer>
          <MenuHeader>{menuTitle}</MenuHeader>
          <CloseIcon onClick={() => props.closeMenu()} src={iconClose} alt="close" />
        </MenuContainer>
      ) : null}
      {props.children}
    </components.Menu>
  );
};

export default Menu;
