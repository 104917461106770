import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import { media } from "utils/media";

// Components
import { SingleTab } from "components/spocketUI/components/tabs/SingleTab";

// Style
const Container = styled.section`
  display: flex;

  ${media.TABLET`
    flex-direction: column;
    border-radius: 12px;
    padding: 7px 0;
    background-color: #FFF;
    margin-bottom: 30px;
  `};
`;

export const Tabs = ({ tabs, activeRoute }) => {
  return (
    <Container>
      {tabs && tabs.map(tab => <SingleTab tab={tab} activeRoute={activeRoute} key={tab.path} />)}
    </Container>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeRoute: PropTypes.string.isRequired
};
