import React, { Fragment } from "react";
import { media } from "utils/media";
import PropTypes from "prop-types";
import styled from "styled-components";

// Styles
const Overlay = styled.div`
  position: fixed;
  background-color: rgba(245, 245, 245, 0.3);
  height: 100vh;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1010;
  transform: ${props => (props.localOpenState ? "translateX(0)" : "translateX(100%)")};
`;

const Container = styled.div`
  position: fixed;
  height: 100%;
  background: #fff;
  top: 0;
  right: 0;
  width: 400px;
  z-index: 1020;
  transform: ${props => (props.localOpenState ? "translateX(0%)" : "translateX(100%)")};
  box-shadow: ${props => (props.localOpenState ? "0px 4px 194px rgba(0, 0, 0, 0.15)" : "none")};
  transition: all 0.3s ease;
  padding: 30px 40px;

  ${media.TABLET`
  width: 300px;
  padding: 30px;
  `};

  ${media.PHONE`
    width: 250px;
    padding: 20px;
  `};
`;

export function Drawer({ children, drawerOpen, setDrawerOpen }) {
  return (
    <Fragment>
      <Overlay
        localOpenState={drawerOpen}
        onClick={() => {
          setDrawerOpen(false);
        }}
      />
      <Container className="drawer-container" localOpenState={drawerOpen}>
        {children}
      </Container>
    </Fragment>
  );
}

Drawer.propTypes = {
  children: PropTypes.node,
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired
};
