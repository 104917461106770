import React from "react";
import { components } from "react-select";
import styled from "styled-components";

const SingleValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 16px;
    height: 12px;
    border-radius: 2px;
    margin-right: 8px;
  }
`;

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <SingleValueContainer>
        {props.hasValue && props.data.icon}
        {children}
      </SingleValueContainer>
    </components.SingleValue>
  );
};

export default SingleValue;
