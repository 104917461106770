import styled from "styled-components";
import colors from "components/spocketUI/theme/colors";

export const Text = styled.p`
  margin-bottom: 0;
  min-width: 0;
  ${props => getStyleWithVariant(props.variant)};
  white-space: ${props => (props.truncate ? "nowrap" : "unset")};
  overflow: ${props => (props.truncate ? "hidden" : "unset")};
  text-overflow: ${props => (props.truncate ? "ellipsis" : "unset")};
`;

const getStyleWithVariant = variant => {
  switch (variant) {
    case "primary":
      return `
        font-size: 14px;
        color: ${colors.grey.colorGrey60};
        font-weight: 500;
      `;
    case "primaryBold":
      return `
        font-size: 14px;
        color: ${colors.grey.colorGrey60};
        font-weight: bold;
      `;
    case "secondary":
      return `
        font-size: 12px;
        color: ${colors.grey.colorGrey60};
        font-weight: 500;
      `;
    case "tertiary":
      return `
        font-size: 12px;
        color: ${colors.grey.colorGrey45};
        font-weight: 500;
      `;
    default:
      return `
        font-size: 14px;
        color: ${colors.grey.colorGrey60};
        font-weight: 500;
      `;
  }
};
