// React
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import colors from "../theme/colors";

const StyledTextArea = styled.textarea`
  background-color: ${colors.grey.colorGrey10};
  border-radius: 4px;
  border: solid 1px #d9d9d9;
  font-size: 12px;
  letter-spacing: 0.3px;
  outline: none;
  padding: 8px 12px 8px 12px;
  width: 100%;
  ${props => !props.resize && "resize: none"};

  ::placeholder {
    opacity: 0.4;
  }
`;

export const TextArea = ({ onChange, placeholder, rows, className, testId, ...props }) => {
  return (
    <StyledTextArea
      data-cy={testId}
      onChange={e => onChange(e)}
      placeholder={placeholder}
      rows={rows}
      className={className}
      {...props}
    />
  );
};

TextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  testId: PropTypes.string
};

TextArea.defaultProps = {
  testId: ""
};
