import styled from "styled-components";

export const Link = styled.a`
  color: #7020ff;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: #7020ff;
  }
`;
