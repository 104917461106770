import styled from "styled-components";

const variant = {
  red: {
    background: "#fff6f5",
    fontColor: "#770d0d"
  },
  blue: {
    background: "#ebf7ff",
    fontColor: "#003757"
  }
};

export const Tag = styled.div`
  border-radius: 4px;
  background-color: ${props => (props.variant ? variant[props.variant].background : variant.blue.background)};
  padding: ${props => (props.size === "small" ? "2px" : "4px 8px")};
  font-size: ${props => (props.size === "small" ? "9px" : "12px")};
  color: ${props => (props.variant ? variant[props.variant].fontColor : variant.blue.fontColor)};
  display: inline-flex;
  font-weight: ${props => (props.size === "small" ? "bold" : "500")};
  user-select: none;
  justify-content: center;
  align-items: center;

  .emoji {
    font-size: ${props => (props.size === "small" ? "10px" : "16px")};
    margin-right: ${props => (props.size === "small" ? "2px" : "4px")};
  }
`;
