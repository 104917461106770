import styled from "styled-components";

export const Input = styled.input`
  border-radius: 4px;
  border: solid 1px #e6e8f0;
  background-color: #fafbfc;
  font-size: 14px;
  line-height: 16px;
  color: #222939;
  padding: 8px;
  width: 100%;
  transition: all 0.08s ease-out;
  ${props => getStyleWithVariant(props.variant)};

  &::placeholder {
    line-height: 1.71;
    color: #6f7582;
  }

  &:focus {
    outline: unset;
    border-color: #bab1ff;
    box-shadow: 0 0 0px 2pt rgba(186, 177, 255, 0.3);
  }

  @media screen and (max-width: 426px) {
    max-height: 42px;
  }
`;

const getStyleWithVariant = variant => {
  switch (variant) {
    case "extraBig":
      return `
        height: 59px;
      `;
    case "big":
      return `
        height: 40px;
      `;
    default:
      return `
        height: 40px;
      `;
  }
};
