import { useQuery } from "@tanstack/react-query";
import { getPricingRules, getSettings, getSquareLocations, getDropshippersData } from "./requests";
import {
  FETCH_DROPSHIPPER_DATA_QUERY,
  FETCH_PRICING_RULES_QUERY,
  FETCH_SETTINGS_QUERY,
  FETCH_SQUARE_LOCATIONS_QUERY
} from "./types";

export const useSettings = () => {
  return useQuery([FETCH_SETTINGS_QUERY], getSettings);
};

export const useSquareLocation = id => {
  return useQuery([FETCH_SQUARE_LOCATIONS_QUERY, () => getSquareLocations(id)]);
};

export const usePricingRule = () => {
  return useQuery([FETCH_PRICING_RULES_QUERY], getPricingRules);
};

export const useDropshipperData = () => {
  return useQuery([FETCH_DROPSHIPPER_DATA_QUERY], getDropshippersData);
};
