import React from "react";
import { useNavigate } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// Styles
import { Container, InnerContainer, Icon, Title, Message, ButtonWrapper, StyledLink } from "./NotFound.style";

export const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleGoToHomepage() {
    navigate("/");
  }

  return (
    <Container>
      <InnerContainer>
        <Icon />
        <Title>{t("NotFoundPage.Title")}</Title>
        <Message>{t("NotFoundPage.Description")}</Message>
        <ButtonWrapper>
          <StyledLink
            data-heap="not-found-page-go-to-home-page"
            title={t("NotFoundPage.Button.Text")}
            onClick={handleGoToHomepage}
          >
            {t("NotFoundPage.Button.Text")}
          </StyledLink>
        </ButtonWrapper>
      </InnerContainer>
    </Container>
  );
};
