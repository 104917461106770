import React from "react";
import styled from "styled-components";

export const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: "";
    margin-right: 16px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    border: 1.5px solid #2c3e50;
    border-radius: 4px;
    background: white;
    float: left;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before {
    background: #7020ff;
    border: none;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    background: white;
    background-size: cover;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjM5OTk4IDAuODI4MTc4QzEuOTIyNTkgMC44MjgxNzggMS40NjQ3NSAxLjAxNzgyIDEuMTI3MTggMS4zNTUzOUMwLjc4OTYxOCAxLjY5Mjk1IDAuNTk5OTc2IDIuMTUwNzkgMC41OTk5NzYgMi42MjgxOFYxNS44MjgyQzAuNTk5OTc2IDE2LjMwNTYgMC43ODk2MTggMTYuNzYzNCAxLjEyNzE4IDE3LjEwMUMxLjQ2NDc1IDE3LjQzODUgMS45MjI1OSAxNy42MjgyIDIuMzk5OTggMTcuNjI4MkgxNS42QzE2LjA3NzQgMTcuNjI4MiAxNi41MzUyIDE3LjQzODUgMTYuODcyOCAxNy4xMDFDMTcuMjEwMyAxNi43NjM0IDE3LjQgMTYuMzA1NiAxNy40IDE1LjgyODJWMi42MjgxOEMxNy40IDIuMTUwNzkgMTcuMjEwMyAxLjY5Mjk1IDE2Ljg3MjggMS4zNTUzOUMxNi41MzUyIDEuMDE3ODIgMTYuMDc3NCAwLjgyODE3OCAxNS42IDAuODI4MTc4SDIuMzk5OThaTTEzLjQ0ODQgNy42NzY1OEMxMy42NjcgNy40NTAyNiAxMy43ODc5IDcuMTQ3MTMgMTMuNzg1MiA2LjgzMjVDMTMuNzgyNSA2LjUxNzg2IDEzLjY1NjIgNi4yMTY4OSAxMy40MzM4IDUuOTk0NEMxMy4yMTEzIDUuNzcxOTEgMTIuOTEwMyA1LjY0NTcgMTIuNTk1NyA1LjY0Mjk3QzEyLjI4MSA1LjY0MDI0IDExLjk3NzkgNS43NjExOSAxMS43NTE2IDUuOTc5NzhMNy43OTk5OCA5LjkzMTM4TDYuMjQ4MzggOC4zNzk3OEM2LjAyMjA1IDguMTYxMTkgNS43MTg5MyA4LjA0MDI0IDUuNDA0MjkgOC4wNDI5N0M1LjA4OTY2IDguMDQ1NyA0Ljc4ODY4IDguMTcxOTEgNC41NjYxOSA4LjM5NDRDNC4zNDM3IDguNjE2ODkgNC4yMTc1IDguOTE3ODYgNC4yMTQ3NyA5LjIzMjVDNC4yMTIwMyA5LjU0NzEzIDQuMzMyOTkgOS44NTAyNiA0LjU1MTU4IDEwLjA3NjZMNi45NTE1OCAxMi40NzY2QzcuMTc2NjEgMTIuNzAxNSA3LjQ4MTc4IDEyLjgyNzkgNy43OTk5OCAxMi44Mjc5QzguMTE4MTcgMTIuODI3OSA4LjQyMzM0IDEyLjcwMTUgOC42NDgzOCAxMi40NzY2TDEzLjQ0ODQgNy42NzY1OFoiIGZpbGw9IiM3ODREREQiLz4KPC9zdmc+Cg==);
  }
`;

const LabelText = styled.span`
  color: #081f40;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: block;
  float: left;
  width: calc(100% - 32px);
  line-height: 150%;
  margin-top: -3px;
`;

export const MultiSelectCheckbox = ({ children, className, checkboxId, checkboxIsChecked, onChange }) => {
  return (
    <div className={className}>
      <StyledCheckbox type="checkbox" id={checkboxId} checked={checkboxIsChecked} onChange={onChange} />
      <label htmlFor={checkboxId}>
        <LabelText>{children}</LabelText>
      </label>
    </div>
  );
};
