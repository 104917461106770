// Libs
import React from "react";
import PropTypes from "prop-types";

// Style
import { Container, Span } from "./Divider.style";

export const Divider = ({ children }) => {
  return (
    <Container>
      <Span>{children}</Span>
    </Container>
  );
};

Divider.propTypes = {
  children: PropTypes.any
};
