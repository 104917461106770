// Libs
import React, { useContext } from "react";
import styled from "styled-components";
import { DropdownContext } from "../Dropdown";

const StyledDropdownItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: inherit;
  border-radius: 8px;
  background-color: ${props =>
    props.checked ? props.theme.colors.purple.colorPurple05 : props.theme.colors.white.colorWhite};
  padding: 0 8px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: ${props => props.theme.colors.grey.colorGrey60};
  height: 32px;
  margin: 4px 0;
  :hover {
    background-color: ${props => props.theme.colors.purple.colorPurple05};
  }
`;

const StyledCheckboxItem = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  outline: inherit;
  border-radius: 8px;
  padding: 0 8px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: ${props => props.theme.colors.grey.colorGrey60};
  height: 32px;
  margin: 4px 0;
  background-color: ${props => props.theme.colors.white.colorWhite};

  :hover {
    background-color: ${props => props.theme.colors.purple.colorPurple05};
  }
`;

export const DropdownPurpleItem = ({ children, onClick, checked }) => {
  const { closeDropdown } = useContext(DropdownContext);
  return (
    <StyledDropdownItem
      checked={checked}
      onClick={() => {
        onClick();
        closeDropdown && closeDropdown();
      }}
    >
      {children}
    </StyledDropdownItem>
  );
};

export const CheckboxDropdownItem = ({ children, onClick, checked }) => {
  return (
    <StyledCheckboxItem checked={checked} onClick={onClick}>
      {children}
    </StyledCheckboxItem>
  );
};
