import React from "react";

import { Label, HiddenRadio, Mark, RadioContainer } from "./radio/RadioStyle";

export const Radio = ({ name, value, checked, onChange, children, disabled, ...props }) => (
  <RadioContainer>
    <Label disabled={disabled}>
      <HiddenRadio
        type="radio"
        onChange={onChange}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        aria-checked={checked}
        {...props}
      />
      <Mark />
      {children}
    </Label>
  </RadioContainer>
);
