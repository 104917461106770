import styled from "styled-components";

// Components
import { Button } from "components/spocketUI/components/Button";
import { media } from "utils/media";

export const Container = styled.div`
  display: flex;
  min-height: calc(100dvh - 60px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InnerContainer = styled.div`
  max-width: 448px;
  text-align: center;
  margin: 0 auto;
`;

export const Icon = styled.div`
  height: 48px;
  width: 48px;
  margin: 0 auto;
  color: ${props => props.theme.colors.purple.colorPurple15};
`;

export const Title = styled.h1`
  font-family: Avenir;
  margin: 0;
  font-size: 28px;
  font-weight: bold;
  line-height: 150%;
  color: ${props => props.theme.colors.blue.colorBlue10};
`;

export const Message = styled.p`
  font-family: Avenir;
  color: ${props => props.theme.colors.blue.colorBlue10};
  margin-top: 16px;
  font-size: 16px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

export const StyledLink = styled(Button)`
  background-color: ${props => props.theme.colors.purple.colorPurple15};
  color: ${props => props.theme.colors.white.colorWhite};
  display: inline-flex;
  align-items: center;
  font-family: Avenir;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  transition: filter 0.3s ease;
  text-decoration: none;
  cursor: pointer;

  ${media.PHONE`
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
  `}

  &:hover {
    filter: brightness(0.9);
  }
`;
