// Libs
import React, { useState } from "react";
import ReactModal from "react-modal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { closeModal } from "utils/actions/ui";

// Style
import "./modal/Modal.css";

const Modal = ({
  children,
  closeModal,
  background = "light",
  clickOutside,
  cleanModalContent,
  shouldCloseOnOverlayClick = true
}) => {
  const [clickedToClose, setClickedToClose] = useState(false);
  const backgroundContent = {
    light: "Spocket-Modal__Overlay__light",
    dark: "Spocket-Modal__Overlay__dark"
  };
  return (
    <ReactModal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      portalClassName="Spocket-Modal"
      overlayClassName={`${cleanModalContent ? "Spocket-Modal__Overlay--clean" : "Spocket-Modal__Overlay"}
      ${clickedToClose ? "ReactModal__Overlay--close" : ""} 
      ${backgroundContent[background]}`}
      className={` 
      ${cleanModalContent ? "Spocket-Modal__Content--clean" : "Spocket-Modal__Content"}`}
      onRequestClose={() => {
        clickOutside && clickOutside();
        setClickedToClose(true);
        setTimeout(() => closeModal(), 300);
      }}
      appElement={document.getElementById("root")}
      isOpen
    >
      {children}
    </ReactModal>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeModal }, dispatch);
}

export default connect(null, mapDispatchToProps)(Modal);
