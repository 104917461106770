const SETTINGS_PATH = "/settings";

export const settings = {
  index: SETTINGS_PATH,
  plans: `${SETTINGS_PATH}/plans`,
  account: `${SETTINGS_PATH}/account`,
  membership: `${SETTINGS_PATH}/membership`,
  store: `${SETTINGS_PATH}/store`,
  pricing: `${SETTINGS_PATH}/pricing`,
  apps: `${SETTINGS_PATH}/apps`,
  chargebeePaymentSuccess: `${SETTINGS_PATH}/chargebee/payment_success`,
  paypalPaymentSuccess: `${SETTINGS_PATH}/paypal/payment_success`,
  paypalPaymentCancel: `${SETTINGS_PATH}/paypal/payment_cancel`,
  paypalBillingAgreementCancel: `${SETTINGS_PATH}/paypal/billing_agreement_cancel`
};

export const auth = {
  login: "/login",
  signup: "/signup",
  alibabaRegister: "/alibaba_register",
  aliexpressRegister: "/aliexpress_register",
  createAccount: "/create-account",
  registration: "/registration",
  register: "/register",
  forgotPassword: "/forgot-password"
};

export const product = {
  index: "/product",
  import: "/import",
  reviews: "/reviews",
  products: "/products",
  winningProducts: "/winning-products",
  checkout: "/checkout",
  payment: "/payment"
};

export const app = {
  index: "/",
  search: "/search",
  maintenance: "/maintenance",
  aliscraper: "/aliscraper",
  imageSearch: "/image-search",
  jubilee: "/jubilee",
  chat: "/chat",
  notifications: "/notifications",
  orders: "/orders",
  pageRedirect: "/redirect/success",
  switchStoreUpgradeSuccess: "/upgrade/success",
  cancelSubscription: "/cancel-subscription"
};

export const legal = {
  privacy: "/privacy",
  terms: "/terms",
  dataProcessingAddendum: "/data-processing-addendum"
};

export const account = {
  connect: "/connect",
  downgrade: "/downgrade"
};

export const promotions = {
  planRedirect: "/plan_redirect",
  spocketDeals: "/spocket-deals",
  academy: "/academy"
};

export const nonAuth = {
  search: "/search/new",
  payment: "/payment/new"
};

export const suppliers = "/suppliers";

export default {
  settings,
  auth,
  product,
  app,
  account,
  legal,
  promotions,
  suppliers,
  nonAuth
};
