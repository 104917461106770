import React from "react";
import styled from "styled-components";

import iconClose from "./images/icon-close.svg";

const Icon = styled.span`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  margin-right: 4px;
  background-color: #e6e8f0;
  transition: all 300ms ease-out;

  :hover {
    background-color: #dce0ea;
  }
`;

const Img = styled.img`
  width: 8px;
  height: 8px;
`;

const CustomClearIcon = () => (
  <Icon>
    <Img src={iconClose} alt="clear all" />
  </Icon>
);

const ClearIndicator = props => {
  const {
    children = <CustomClearIcon />,
    innerProps: { ref, ...restInnerProps }
  } = props;

  return (
    <div {...restInnerProps} ref={ref}>
      {children}
    </div>
  );
};

export default ClearIndicator;
