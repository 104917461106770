import styled from "styled-components";

const RadioContainer = styled.div`
  display: inline-block;
`;

const Mark = styled.span`
  display: inline-block;
  position: relative;
  border: solid 1px #ccd2e1;
  width: 16px;
  height: 16px;
  left: 0;
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: middle;
  transition: all 300ms;
  background-color: white;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: white;
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
  }
`;

// Hide radio visually but remain accessible to screen readers.
const HiddenRadio = styled.input.attrs({ type: "radio" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  transition: all 300ms ease-out;

  &:hover + ${Mark} {
    border-color: #7020ff;
  }

  &:focus + ${Mark} {
    box-shadow: 0 0 0 2px #e9e6ff;
  }

  &:checked + ${Mark} {
    background-color: #7020ff;
    border-color: #571abc;
    &::after {
      width: 6px;
      height: 6px;
      opacity: 1;
      left: 30%;
      top: 30%;
    }
  }

  &:disabled + ${Mark} {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:disabled:hover + ${Mark} {
    border: solid 1px #ccd2e1;
  }
`;

const Label = styled.label`
  display: inline-flex;
  cursor: pointer;
  padding: 5px 10px 5px 0;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: #222939;

  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
`;
export { Label, HiddenRadio, Mark, RadioContainer };
