import styled, { css } from "styled-components";

export const Badge = styled.div`
  ${({ theme: { colors, spaces } }) => css`
    background-color: ${colors.green.colorGreen10};
    color: ${colors.green.colorGreen50};
    padding: ${spaces.space10} ${spaces.space20};
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    text-align: center;
  `};
`;
