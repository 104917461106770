// Libs
import React from "react";
import styled from "styled-components";

const DropdownTrigger = ({ children, onClick, disabled }, props) => {
  return (
    <ButtonNoStyle disabled={disabled} onClick={() => onClick()}>
      {children}
    </ButtonNoStyle>
  );
};

const ButtonNoStyle = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;

  :disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export default DropdownTrigger;
