import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import { Input } from "components/spocketUI/";
import { Typography, Flex, Tooltip, Button } from "components/spocketUI";

// Constants
import { APP_ASSETS_BUCKET_URL } from "constants/common";

// assets
const infoIcon = `${APP_ASSETS_BUCKET_URL}/info-icon.svg`;

const Wrapper = styled.div`
  height: 90px;
  input[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledInput = styled(Input)`
  margin-top: 2px;
  background: ${props => props.theme.colors.white.colorWhite};
`;

const Label = styled(Typography.p)`
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  padding: 0;
  color: ${props => props.theme.colors.blue.colorBlue10};
  opacity: 0.7;
  ${props => props.disabled && `opacity: 0.5; cursor: not-allowed;`};
  &.label-dark {
    opacity: 1;
  }
`;

const ErrorText = styled.p`
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.red.colorWarning};
`;

const TooltipWrapper = styled.div`
  margin-left: 10px;
`;

const StyledTextArea = styled.textarea`
  margin-bottom: 40px;
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.grey.colorGrey30};
  background-color: ${props => props.theme.colors.grey.colorGrey10};
  font-size: 14px;
  line-height: 16px;
  resize: none;
  color: ${props => props.theme.colors.grey.colorGrey60};
  padding: 8px;
  width: 100%;
  transition: all 0.08s ease-out;

  &::placeholder {
    line-height: 1.71;
    color: ${props => props.theme.colors.grey.colorGrey50};
  }

  &:focus {
    outline: unset;
    border-color: ${props => props.theme.colors.purple.colorPurple11};
    box-shadow: 0 0 0px 2pt rgba(186, 177, 255, 0.3);
  }
`;

export const Trigger = styled.div`
  background: ${props => props.theme.colors.white.colorWhite};
  border: solid 1px ${props => props.theme.colors.grey.colorGrey30};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: max-content;
  cursor: pointer;
  width: 100%;
`;

export const TriggerText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.black.colorBlack10};
  margin-bottom: 0;
  text-transform: capitalize;
`;

export const StyledButton = styled(Button)`
  width: fit-content;
  padding: 8px 16px;
  min-width: 150px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0px")};
`;

export const TriggerIcon = styled.img``;

export const SettingsLabel = ({ children, className }) => (
  <Label variant="secondary" className={className}>
    {children}
  </Label>
);

export const SettingsInput = ({
  label,
  onChange,
  value,
  placeholder,
  hasError,
  error,
  message,
  altText,
  large,
  disabled,
  type
}) => (
  <Wrapper>
    <Flex>
      <Label variant="secondary" disabled={disabled}>
        {label}
      </Label>
      {message && (
        <TooltipWrapper>
          <Tooltip message={message}>
            <img src={infoIcon} alt={`tooltip-${altText}`} />
          </Tooltip>
        </TooltipWrapper>
      )}
    </Flex>
    {!large && (
      <StyledInput
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        type={type}
        min="0"
      />
    )}
    {large && (
      <StyledTextArea
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value}
        data-testid="settings-text-area"
      />
    )}
    <ErrorText>{hasError && error}</ErrorText>
  </Wrapper>
);

SettingsInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  error: PropTypes.string,
  message: PropTypes.string,
  altText: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

SettingsInput.defaultProps = {
  error: "",
  message: "",
  altText: "",
  disabled: false,
  type: "text"
};
