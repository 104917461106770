import styled from "styled-components";

// Components
import { Button } from "components/spocketUI";

// Media
import { media } from "utils/media";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  padding: 24px;
  width: 100%;
  text-align: center;
  max-width: 500px;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.colors.blue.colorBlue10};
  margin-bottom: 16px;
  font-family: Avenir;
`;

export const Message = styled.p`
  color: ${props => props.theme.colors.blue.colorBlue10};
  margin-bottom: 24px;
  font-family: Avenir;
  font-size: 16px;
`;

export const StyledButton = styled(Button)`
  background-color: ${props => props.theme.colors.purple.colorPurple15};
  color: ${props => props.theme.colors.white.colorWhite};
  display: inline-flex;
  align-items: center;
  font-family: Avenir;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  transition: filter 0.3s ease;
  text-decoration: none;
  cursor: pointer;

  ${media.PHONE`
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
  `}

  &:hover {
    filter: brightness(0.9);
  }
`;
