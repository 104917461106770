// Libs
import React from "react";

import { DropdownMenu } from "./DropdownContentStyle";

const DropdownContent = ({ children, position, setActive, minWidth }) => {
  return (
    <DropdownMenu className="dropdown" position={position} minWidth={minWidth} setActive={setActive}>
      {children}
    </DropdownMenu>
  );
};

export default DropdownContent;
