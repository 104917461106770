import React from "react";
import styled from "styled-components";

export const Typography = styled(React.Fragment)``;

const H1 = styled.h1`
  font-size: 28px;
  color: #2e3039;
  font-weight: bold;

  margin: 0 0 8px 0;
  padding: 0;
`;

const H2 = styled.h2`
  font-size: 22px;
  color: #2e3039;
  font-weight: bold;
  box-sizing: border-box;
  /* CSS RESET */
  margin: 0;
  padding: 0 0 8px 0;
`;

const H3 = styled.h3`
  font-size: 18px;
  color: #2e3039;
  font-weight: bold;
  box-sizing: border-box;
  padding: ${props => (props.padding ? props.padding : "0 0 8px 0")};
  /* CSS RESET */
  margin: 0;
`;

const H4 = styled.h4`
  font-size: 16px;
  color: #2e3039;
  font-weight: 500;
  /* CSS RESET */
  margin: 0 0 8px 0;
  padding: 0;
`;

const P = styled.p`
  font-weight: 500;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${props => getPVariant(props)};
  ${props => getPSize(props)};
  padding: ${props => (props.padding ? props.padding : "0 0 12px 0")};

  /* CSS RESET */
  margin: 0;
`;

const getPVariant = props => {
  const { variant } = props;

  switch (variant) {
    case "primary":
      return `color: ${props.theme.colors.black.colorBlack10};`;
    case "secondary":
      return `color: ${props.theme.colors.grey.colorGrey50};`;
    default:
      return `color: ${props.theme.colors.black.colorBlack10};`;
  }
};

const getPSize = props => {
  const { size } = props;

  switch (size) {
    case "sm":
      return `font-size: ${props.theme.fontSizes.fontSize10};`;
    case "md":
      return `font-size: ${props.theme.fontSizes.fontSize20};`;
    case "lg":
      return `font-size: ${props.theme.fontSizes.fontSize40};`;
    default:
      return `font-size: ${props.theme.fontSizes.fontSize30};`;
  }
};

Typography.H1 = H1;
Typography.H2 = H2;
Typography.H3 = H3;
Typography.H4 = H4;
Typography.p = P;
