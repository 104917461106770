import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Components
import { Dropdown, CheckboxDropdownItem, Flex } from "components/spocketUI";
import { StyledCheckbox } from "components/spocketUI/components/MultiSelectCheckbox";
import { TriggerIcon, TriggerText } from "components/Settings/settingsAssets";

// Constants
import { APP_ASSETS_BUCKET_URL } from "constants/common";
import { media } from "utils/media";

// Assets
const iconExpand = `${APP_ASSETS_BUCKET_URL}/icon-expand.svg`;

// Style
const StyledDropDown = styled(Dropdown)`
  .dropdown {
    border-radius: 12px;
    margin-top: 8px;
    padding: 12px 16px;
    overflow-y: auto;
    height: auto;
    max-height: 200px;

    ${media.PHONE` 
       button {
        padding-block: 6px;
        height: auto;

        label:before {
          margin-right: 8px;
        }
    }
    `}
  }
`;

const StyledTrigger = styled.div`
  background: ${props => props.theme.colors.white.colorWhite};
  border: solid 1px ${props => props.theme.colors.grey.colorGrey30};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  cursor: pointer;
  width: 100%;
`;

const SelectedOption = styled.p`
  display: inline-block;
  padding: 4px 10px;
  background: rgba(120, 77, 221, 0.1);
  border-radius: 4px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.purple.colorPurple15};
  margin-bottom: 0;
  margin-right: 12px;

  ${media.PHONE`
    max-width: 70vw; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 7px;
    margin-right: 7px;
  `}
`;

const Checkbox = styled(StyledCheckbox)`
  && {
    margin-right: 16px;
  }
`;

const Label = styled.label`
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.0015em;
  margin-bottom: 0;
  color: ${props => props.theme.colors.blue.colorBlue10};
`;

export const MultiSelect = ({ options, selectedValues, setSelectedOption, placeholder }) => {
  // Render trigger/Placeholder
  // If items are selected, show items on trigger
  // If no items are selected, show placeholder
  const renderTriggerInfo = () => {
    const hasSelectedValues = selectedValues.length > 0;

    // Check if inside the selected values there is at least one that is valid (exists inside the options)
    const validSelectedValues = selectedValues.filter(selectionValue => {
      const optionData = options.find(opt => opt.value === selectionValue);
      return optionData;
    });
    const hasAtLeastOneValidSelectedValue = validSelectedValues.length > 0;

    if (hasSelectedValues && hasAtLeastOneValidSelectedValue) {
      return (
        <Flex flexWrap="wrap">
          {selectedValues
            .map(selectionValue => {
              const optionData = options.find(opt => opt.value === selectionValue);
              if (optionData) {
                return <SelectedOption key={optionData.value}>{optionData.label}</SelectedOption>;
              } else {
                return false;
              }
            })
            .filter(value => value)}
        </Flex>
      );
    } else {
      return <TriggerText>{placeholder}</TriggerText>;
    }
  };

  // Show each dropdown option with a checkbox on the side
  const renderDropdownOptions = () => {
    return options.map(option => {
      const { value, label } = option;
      const isSelected = selectedValues.includes(value);

      return (
        <CheckboxDropdownItem
          className="collection-dropdown-item"
          checked={isSelected}
          onClick={() => setSelectedOption(value)}
          key={value}
        >
          <Checkbox
            type="checkbox"
            checked={isSelected}
            onChange={() => {
              // Just to avoid the defaultChecked error
              return;
            }}
          />
          <Label>{label}</Label>
        </CheckboxDropdownItem>
      );
    });
  };

  return (
    <div data-testid="multi-select">
      <StyledDropDown position="right">
        <Dropdown.Trigger>
          <StyledTrigger>
            {renderTriggerInfo()}
            <TriggerIcon src={iconExpand} alt="expand" />
          </StyledTrigger>
        </Dropdown.Trigger>
        <Dropdown.Content minWidth="fill-available">{renderDropdownOptions()}</Dropdown.Content>
      </StyledDropDown>
    </div>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.array,
  selectedValues: PropTypes.array,
  setSelectedOption: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};
