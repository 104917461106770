import React, { useEffect, Fragment } from "react";
import { Userpilot } from "userpilot";
import { useLocation } from "react-router-dom";

export const PageWrapper = ({ children }) => {
  const pageLocation = useLocation();

  useEffect(() => {
    Userpilot.reload();
  }, [pageLocation]);

  return <Fragment>{children}</Fragment>;
};

export const withPageWrapper = Component => {
  return props => (
    <PageWrapper>
      <Component {...props} />
    </PageWrapper>
  );
};
