import { lazy } from "react";
import paths from "../paths";
import { withPageWrapper } from "components/PageWrapper";

const Cancellation = lazy(() =>
  import("pages/cancellation").then(module => ({ default: module.Cancellation }))
);
const ConnectStore = lazy(() => import("pages/ConnectStore"));

export const accountRoutes = [
  { path: paths.account.downgrade, Component: withPageWrapper(Cancellation), app: true },
  { path: paths.account.connect, Component: withPageWrapper(ConnectStore), app: true }
];
