import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Components
import { Typography } from "components/spocketUI";

// Utils
import { media } from "utils/media";

// Constants
import { APP_ASSETS_BUCKET_URL } from "constants/common";

// Assets
const warningIcon = `${APP_ASSETS_BUCKET_URL}/info-icon-warning.svg`;

// Style
const Container = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: ${props => props.theme.colors.orange.colorOrange40};
  background-color: ${props => props.theme.colors.yellow.colorYellow10};
  border-radius: 16px;

  ${media.TABLET`
  margin-top: 10px;
  margin-bottom: 10px;
`};
`;

const Image = styled.img`
  margin-right: 12px;
`;

const Text = styled(Typography.p)`
  color: ${props => props.theme.colors.orange.colorOrange40};
  font-size: 12px;
  line-height: 20px;
  padding: 0;
  margin-bottom: 0;
`;

export const YellowAlert = ({ children, text }) => {
  return (
    <Container>
      <Image src={warningIcon} alt="Warning Label" aria-label="yellow-warning-image" />
      <Text>{text}</Text>
      {children}
    </Container>
  );
};

YellowAlert.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node
};
