import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e7e7e7;
  line-height: 0.1em;
  color: #7e768f;
  font-weight: 600;
`;

export const Span = styled.span`
  background: #fff;
  padding: 0 10px;
`;
