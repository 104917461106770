import styled from "styled-components";
import colors from "components/spocketUI/theme/colors";

export const Button = styled.button`
  border: 1px solid;
  border-radius: 4px;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: 0.2px;
  transition: background-color 0.3s ease-in-out;
  width: ${props => (props.full ? "100%" : "unset")};
  ${props => getStyleWithVariant(props.variant)};

  &:hover {
    ${props => getHoverStyleWithVariant(props.variant)};
  }

  &:disabled {
    background-color: #f4f5f8;
    border-color: #e6e8f0;
    color: #ccd2e1;
    cursor: not-allowed;
  }

  &:disabled:hover {
    background-color: #f4f5f8;
    border-color: #e6e8f0;
    color: #ccd2e1;
    cursor: not-allowed;
  }
`;

const getStyleWithVariant = variant => {
  switch (variant) {
    case "basic":
      return `
        border-color: ${colors.grey.colorGrey30};
        background-color: ${colors.grey.colorGrey10};
        color: ${colors.grey.colorGrey60};
        font-size: 12px;
        padding: 4px 8px;
      `;
    case "basicBig":
      return `
        border-color: ${colors.grey.colorGrey30};
        background-color: ${colors.grey.colorGrey10};
        color: ${colors.grey.colorGrey60};
        font-size: 14px;
        padding: 8px 16px;
      `;
    case "primary":
      return `
        border-color: ${colors.green.colorGreen45};
        background-color: ${colors.green.colorGreen40};
        color: ${colors.white.colorWhite};
        font-size: 12px;
        padding: 4px 8px;
      `;
    case "primaryBig":
      return `
        border-color: ${colors.green.colorGreen45};
        background-color: ${colors.green.colorGreen40};
        color: ${colors.white.colorWhite};
        font-size: 14px;
        padding: 8px 16px;
      `;
    case "brand":
      return `
        border-color: ${colors.purple.colorPurple20};
        background-color: ${colors.purple.colorPurple15};
        color: ${colors.white.colorWhite};
        font-size: 12px;
        padding: 4px 8px;
      `;
    case "brandBig":
      return `
        border-color: ${colors.purple.colorPurple20};
        background-color: ${colors.purple.colorPurple15};
        color: ${colors.white.colorWhite};
        font-size: 14px;
        padding: 8px 16px;
      `;
    case "brandBigOutlined":
      return `
        border-color: ${colors.purple.colorPurple20};
        background-color: transparent;
        color: ${colors.purple.colorPurple15};
        font-size: 14px;
        padding: 8px 16px;
      `;
    case "text":
      return `
        border: unset;
        background-color: transparent;
        color: ${colors.grey.colorGrey50};
        font-size: 12px;
        padding: 0;
      `;
    case "textBig":
      return `
        border: unset;
        background-color: transparent;
        color: ${colors.grey.colorGrey50};
        font-size: 14px;
        padding: 8px 16px;
      `;
    case "warning":
      return `
        border: unset;
        background-color: ${colors.red.colorWarning};
        color: ${colors.white.colorWhite};
        font-size: 12px;
        padding: 4px 8px;
      `;
    case "noStyle":
      return `
        border: unset;
        background-color: unset;
        color: unset;
        padding: unset;
      `;
    default:
      return `
        border-color: ${colors.green.colorGreen45};
        background-color: ${colors.green.colorGreen40};
        color: ${colors.white.colorWhite};
        font-size: 12px;
        padding: 4px 8px;
      `;
  }
};

const getHoverStyleWithVariant = variant => {
  switch (variant) {
    case "basic":
      return `
        background-color: ${colors.grey.colorGrey20};
        border-color: ${colors.grey.colorGrey40};
      `;
    case "basicBig":
      return `
        background-color: ${colors.grey.colorGrey20};
        border-color: ${colors.grey.colorGrey40};
      `;
    case "primary":
      return `
        background-color: ${colors.grey.colorGreen46};
        border-color: ${colors.grey.colorGreen48};
      `;
    case "primaryBig":
      return `
        background-color: ${colors.green.colorGreen46};
        border-color: ${colors.green.colorGreen48};
      `;
    case "brand":
      return `
        background-color: ${colors.purple.colorPurple20};
      `;
    case "brandBig":
      return `
        background-color: ${colors.purple.colorPurple20};
      `;
    case "brandBigOutlined":
      return `
        background-color: ${colors.purple.colorPurple20};
        color: ${colors.white.colorWhite};
      `;
    case "text":
      return `
        color: ${colors.grey.colorGrey55};
      `;
    case "textBig":
      return `
        color: ${colors.grey.colorGrey55};
      `;
    case "warning":
      return `
        background-color: ${colors.red.colorWarningDark};
      `;
    default:
      return `
        background-color: ${colors.grey.colorGreen46};
        border-color: ${colors.grey.colorGreen48};
      `;
  }
};
