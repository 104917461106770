import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 90%;
`;

export const VerticalLine = styled.div`
  border-left: 1px solid
    ${props => props.theme.colors.addOpacity({ color: props.theme.colors.black.colorBlack300, opacity: 0.2 })};
  height: 100%;
  min-height: 70px;
`;

export const Span = styled.span`
  background-color: ${props => props.theme.colors.white.colorWhite};
  color: ${props =>
    props.theme.colors.addOpacity({ color: props.theme.colors.black.colorBlack300, opacity: 0.5 })};
  padding: 12px 0;
`;
