import styled from "styled-components";

const Wrapper = styled.div`
  background-color: ${props => props.backgroundColor};
  width: fit-content;
  padding: 10px 15px;
  border-radius: 9px;
  margin: 0 auto 25px;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
`;

const StyledImage = styled.img`
  height: 16px;
`;

export { Wrapper, StyledImage };
