import styled from "styled-components";

const DropdownMenu = styled.div`
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(34, 41, 57, 0.15);
  background-color: #ffffff;
  padding: 16px;
  position: absolute;
  z-index: 10;
  min-width: ${props => (props.minWidth ? props.minWidth : "200px")};
  right: ${props => (props.position === "right" ? "0" : "unset")};
  bottom: ${props => (props.position === "top" ? "100%" : "unset")};
`;

export { DropdownMenu };
