import styled, { keyframes } from "styled-components";

const skeletonAnimation = () => {
  const skeletonLoading = keyframes`

    to {
      background-position-x: -200%;
    }
  `;
  return skeletonLoading;
};

export const Skeleton = styled.span`
  display: block;
  background: linear-gradient(110deg, #4b5b720d 0%, #4b5b721a 50%, #4b5b720d 100%);
  background-size: 200% 100%;
  animation: 1.5s ${skeletonAnimation()} linear infinite;
  height: ${props => (props.height ? `${props.height}` : "20px")};
  width: ${props => (props.width ? `${props.width}` : "100px")};
  margin-bottom: ${props => (props.marginBottom ? `${props.marginBottom}` : "0")};
  margin-top: ${props => (props.marginTop ? `${props.marginTop}` : "0")};
  border-radius: ${props => (props.isCircle ? "50%" : "5px")};
`;
