export const MEDIA_QUERIES = {
  EXTRA_SMALL_PHONE: { type: "max", size: 390 },
  SMALL_PHONE: { type: "max", size: 470 },
  NOT_SMALL_PHONE: { type: "min", size: 471 },
  PHONE: { type: "max", size: 650 },
  NOT_PHONE: { type: "min", size: 651 },
  SIDEBAR: { type: "max", size: 768 },
  TABLET: { type: "max", size: 940 },
  NOT_TABLET: { type: "min", size: 941 },
  EXTRA_SMALL_DESKTOP: { type: "max", size: 1100 },
  SMALL_DESKTOP: { type: "max", size: 1200 },
  NOT_SMALL_DESKTOP: { type: "min", size: 1201 },
  DESKTOP: { type: "min", size: 941 },
  LARGE_DESKTOP: { type: "max", size: 1400 },
  MEDIUM_LARGE_DESKTOP: { type: "max", size: 1600 },
  EXTRA_LARGE_DESKTOP: { type: "max", size: 2000 },
  NOT_EXTRA_LARGE_DESKTOP: { type: "min", size: 2000 }
};

export const media = Object.keys(MEDIA_QUERIES).reduce((acc, key) => {
  const { type, size } = MEDIA_QUERIES[key];
  acc[key] = style => `
      @media (${type}-width: ${size}px) {
        ${style};
      }
    `;
  return acc;
}, {});
