import React, { memo } from "react";
import { components } from "react-select";
import styled from "styled-components";
import iconCheck from "./images/icon-check.svg";

const IconOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
`;

const Icon = styled.span`
  margin-right: 10px;
  width: 16px;
  height: 12px;
  display: inline-flex;
  align-items: center;

  img {
    width: 16px;
    height: 12px;
    border-radius: 2px;
  }
`;

const Label = styled.span`
  text-align: left;
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SelectedIcon = styled.span`
  width: 16px;
  height: 16px;
  background-color: #dce0ea;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconOption = props => {
  return (
    <components.Option {...props}>
      <IconOptionContainer>
        <Title>
          {props.data.icon && <Icon>{props.data.icon}</Icon>}
          <Label>{props.data.label}</Label>
        </Title>
        {props.isSelected && (
          <SelectedIcon>
            <img src={iconCheck} alt="Icon Check" />
          </SelectedIcon>
        )}
      </IconOptionContainer>
    </components.Option>
  );
};

export default memo(IconOption);
