import styled from "styled-components";
import colors from "components/spocketUI/theme/colors";

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.grey.colorGrey30};
  padding: 16px;
  background-color: ${colors.white.colorWhite};
  width: ${props => (props.width ? props.width : "auto")};
  transition: border 0.21s ease-out;

  &:hover {
    border-color: ${colors.grey.colorGrey40};
  }
`;
