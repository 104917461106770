import styled from "styled-components";

export const Container = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  background-color: ${props =>
    props.checked ? props.theme.colors.grey.colorGrey20 : props.theme.colors.white.colorWhite};
  padding: 4px 8px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: ${props => props.theme.colors.grey.colorGrey60};
  margin-bottom: 8px;
  :hover {
    background-color: ${props => props.theme.colors.grey.colorGrey20};
  }
`;
