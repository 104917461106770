import React, { Fragment } from "react";

import { Range, getTrackBackground } from "react-range";

import { Track, Thumb, TrackBackground, Label, Tooltip } from "./inputRange/InputRangeStyle";

export const InputRange = ({ minLabel, maxLabel, min, max, values = [0, 100], onChange }, props) => {
  return (
    <Range
      step={1}
      min={min}
      max={max}
      onChange={onChange}
      allowOverlap={false}
      values={values}
      renderTrack={({ props, children }) => (
        <Fragment>
          <TrackBackground>
            <Track
              {...props}
              getTrackBackground={getTrackBackground}
              style={{
                background: getTrackBackground({
                  values,
                  colors: ["#ccd2e1", "#7020ff", "#ccd2e1"],
                  min,
                  max
                })
              }}
            >
              {children}
            </Track>
          </TrackBackground>
          <Label>
            <span>{minLabel}</span>
            <span>{maxLabel}</span>
          </Label>
        </Fragment>
      )}
      renderThumb={({ index, props }) => {
        const value = values[index];

        return (
          <Thumb {...props}>
            {index === 0 && min !== value && <Tooltip>{value}</Tooltip>}
            {index === 1 && max !== value && <Tooltip>{value}</Tooltip>}
          </Thumb>
        );
      }}
      {...props}
    />
  );
};
