// Libs
import React, { cloneElement, useState, Fragment, createContext, useEffect } from "react";
import styled from "styled-components";

// Components
import DropdownTrigger from "./dropdown/DropdownTrigger";
import DropdownContent from "./dropdown/DropdownContent";
import DropdownItem from "./dropdown/DropdownItem";
import ClickOutside from "components/ClickOutside";

export const DropdownContext = createContext({});

export const Dropdown = ({ children, position, className, getDropdownValue, onActive = null }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!active && getDropdownValue) getDropdownValue(false);
    if (onActive) {
      onActive(active);
    }
  }, [active]);

  const onToggleClick = () => {
    setActive(!active);
    if (getDropdownValue) getDropdownValue(!active);
  };

  const boundChildren = React.Children.map(children, child => {
    if (child.type === DropdownTrigger) {
      child = cloneElement(child, { onClick: onToggleClick });
    } else if (child.type === DropdownContent && !active) {
      child = null;
    } else if (child.type === DropdownContent && active) {
      child = cloneElement(child, {
        className: "dropdown",
        position: position
      });
    }

    return child;
  });

  return (
    <DropdownContext.Provider value={{ closeDropdown: () => setActive(false), isDropdownActive: active }}>
      <DropdownWrap className={`${className} dropdown-wrap`}>
        <ClickOutside onClick={() => setActive(false)}>
          <Fragment>{boundChildren}</Fragment>
        </ClickOutside>
      </DropdownWrap>
    </DropdownContext.Provider>
  );
};

const DropdownWrap = styled.div`
  position: relative;
  width: 100%;
`;

Dropdown.Trigger = DropdownTrigger;
Dropdown.Content = DropdownContent;
Dropdown.Item = DropdownItem;
