import React from "react";

/*
    An API that corresponds to the Flex API. The only extra prop we have is container to decide whether or not to use display: flex 
    
    <Flex container justifyContent="space-between" width="300px">
        <h1> Developers </h1>
        <h2> Developers </h2>
        <h3> Developers </h3>
    </Flex>; 
*/

export const Flex = props => (
  <div
    className={props.className}
    style={{
      display: props.display ? props.display : "flex",
      justifyContent: props.justifyContent || "flex-start",
      flexDirection: props.flexDirection || "row",
      flexGrow: props.flexGrow || 0,
      flexBasis: props.flexBasis || "auto",
      flexShrink: props.flexShrink || 1,
      flexWrap: props.flexWrap || "nowrap",
      flex: props.flex || "0 1 auto",
      alignItems: props.alignItems || "stretch",
      margin: props.margin || "0",
      marginTop: props.marginTop || "0",
      marginBottom: props.marginBottom || "0",
      padding: props.padding || "0",
      width: props.width || "auto",
      height: props.height || "auto",
      maxWidth: props.maxWidth || "none",
      gap: props.gap || "0"
    }}
  >
    {props.children}
  </div>
);
